import HomeEmail from '@/components/pages/HomeEmail.vue';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { indexRoutesNames } from './routeNames';

const ResetPasswordTrigger = () => import('@/components/pages/ResetPasswordTrigger.vue');
const ResetPasswordConfirmation = () => import('@/components/pages/ResetPasswordConfirmation.vue');
const ResetPasswordInput = () => import('@/components/pages/ResetPasswordInput.vue');

/**
 * The router configuration. It uses standard Vue syntax. If you want to guard a route, use `meta` object.
 * Possible options are:
 * - `meta: { requiresAuth: true }` for routes that require authentication
 * - `meta: { allowFromName: [] }` for routes that can be accessed from the given names
 * - `meta: { requireFeatureFlagKey: string as keyof typeof featureFlagsNames }` for routes that can be accessed only if certain feature flag is enabled
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/reset-password-trigger',
    component: ResetPasswordTrigger,
    name: indexRoutesNames.resetPasswordTrigger,
  },
  {
    path: '/reset-password-confirmation',
    component: ResetPasswordConfirmation,
    name: indexRoutesNames.resetPasswordConfirmation,
    meta: { allowFromName: [indexRoutesNames.resetPasswordTrigger] },
  },
  {
    path: '/reset-password-input',
    component: ResetPasswordInput,
    name: indexRoutesNames.resetPasswordInput,
  },
  {
    path: '/:pathMatch(.*)*',
    component: HomeEmail,
    name: indexRoutesNames.home,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export { router };
