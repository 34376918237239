import MfsApp from '@/App.vue';
import '@/assets/styles/tailwind.css';
import { i18n } from '@/plugins/i18n';
import { router } from '@/router';
import { generateCssRootCustomProperties } from '@/utils/cssUtil';
import { createApp } from 'vue';

// set css custom properties
generateCssRootCustomProperties();

const app = createApp(MfsApp);

app.use(router);
app.use(i18n);

app.mount('#app');
