import { createI18n, type Locale, type LocaleMessages, type VueMessageType } from 'vue-i18n';

type fileGroups = {
  fileName?: string;
  locale?: Locale;
  context?: string;
  localeFolder?: string;
};

const compileStrings = (modules: any): { [key: Locale]: LocaleMessages<VueMessageType> } => {
  const compiled: { [key: Locale]: LocaleMessages<VueMessageType> } = {};
  const fileRegex = /(?<context>\w*)\/(?<localeFolder>\w+)\/(?<fileName>\w+)\.(?<locale>\w+)\.json$/;

  for (const path in modules) {
    const { fileName, locale }: fileGroups = path.match(fileRegex)?.groups ?? {};
    compiled[locale] = compiled[locale] ? compiled[locale] : {};
    compiled[locale][fileName] = modules[path].default;
  }

  return compiled;
};

const messages: { [key: Locale]: LocaleMessages<VueMessageType> } = {
  ...compileStrings(import.meta.glob('../locales/**/**/*.json', { eager: true })),
};

export const i18n = createI18n({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'en',
  messages,
});
