<template>
  <main>
    <router-view />
  </main>
</template>

<style>
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-height: 100vh;
}
</style>
