<template>
  <MfsMetroLogoBackground>
    <CoreLayoutV2 :is-with-sticky-header="false" :is-standalone-page="true" :hide-header-on-desktops="false">
      <template v-slot:body>
        <div class="mfs-container">
          <MfsGrid>
            <div class="md:text-center">
              <MfsHeader predefined-style="page-header">
                {{ t('homeEmail.heading') }}
              </MfsHeader>
            </div>
          </MfsGrid>

          <MfsGrid>
            <template v-slot:body> </template>
          </MfsGrid>
        </div>
      </template>
    </CoreLayoutV2>
  </MfsMetroLogoBackground>
</template>

<script setup lang="ts">
import MfsMetroLogoBackground from '@/components/atoms/background/MfsMetroLogoBackground.vue';
import MfsHeader from '@/components/atoms/MfsHeader.vue';
import CoreLayoutV2 from '@/components/templates/CoreLayoutV2.vue';
import MfsGrid from '@/components/templates/MfsGrid.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
